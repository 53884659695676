import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Button, Form } from "react-bootstrap";

function Donate() {
    const navigate = useNavigate();

    const [amount, setAmount] = useState('');
    const [errors, setErrors] = useState({})

    const validate = () => {
        let validationErrors = {};
        if (!amount) {
            validationErrors.amount = 'Amount is required';
        } else if (isNaN(amount) || amount <= 0) {
            validationErrors.amount = 'Please enter a valid amount';
        }
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };


    const handleNext = (e) => {
        e.preventDefault();
        if (validate()) {
            localStorage.setItem("amount", amount)
            navigate('/complete/donate/checkout');
        }
    };

    return (
        <div>
            <div className="donate-bottom mb-4">
                <div className="">
                    <h1 className='text-center dontate-title'>Donate what you can</h1>

                    <div className="row mt-4">
                        <div className="col-md-8">
                            {/* <Form.Label htmlFor="donatAmount">Amount</Form.Label> */}
                            <Form.Control
                                type="number"
                                id="donatAmount"
                                placeholder="Enter Amount"
                                className='donat-input'
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                        <div className="col-md-4">
                            <Button variant="warning" className='btn-donate mt-2' onClick={handleNext}>Donate </Button>
                        </div> 
                    </div>
                    {errors.amount && <p style={{ color: 'red' }}>{errors.amount}</p>}
                </div>
            </div>
        </div>
    )
}
export default Donate;