import React from "react";
import { Link } from "react-router-dom";
import { Image, Button } from "react-bootstrap";

import LivelihoodImg from '../../assets/programs/livelihood_5.jpg'
import climateImg from '../../assets/programs/climat.jpg'
import LeaderShipImg from '../../assets/awci-train-leadership.jpeg'
import EnterpriseImg from '../../assets/Enterprise.jpeg'
import Seedling from '../../assets/programs/seedling.png'
import Donate from "../shared/Donate";


function Enterprise() {
    return (
        <div className="container">
            <div>
                <div >
                    <div className="text-center pt-3">
                        <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
                    </div>
                    <h1 className="text-center whyus-title">Enterprise Development and Strengthening</h1>
                    <hr />
                    <Image src={EnterpriseImg} alt="" className="img-progam" />
                    <hr />
                    <div className="program-container">
                        <div>
                            <div className="wrap-program">
                                To enhance the capacity and willingness of women and youth entrepreneurial skills and knowledge to innovatively create, organize and manage their business ventures sustainably.
                            </div>

                            <div className="wrap-program">
                                Improve life skills (like planning, goal setting, risk-taking, creativity, negotiation, and presentation skills)
                                of women and youth in competitive business environments.
                            </div>

                            <div className="wrap-program">
                                Increase self-reliance and confidence levels among women and youth.
                            </div>

                            <div className="wrap-program">
                                Improve levels of women and youth economic empowerment and gender equality
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <hr />
            <div className="subprogramCard">

                <div className='progam-content'>
                    <div>
                        <div className="p-2">
                            <Image className='subprogramImg ' src={LivelihoodImg} alt="whyus img" />
                        </div>
                        <div className="subtitle-wrap">
                            <h1 className='whyus-title'>Livelihood</h1>
                        </div>
                        <div className="subwrap">
                            To reduce poverty and inequality by generating employment among households by moving highly vulnerable households into sustainable livelihoods and towards economic stability.
                        </div>
                        <Link to='/our-programs/livelihood'><Button className='donate-btn' variant="info">Read More</Button></Link>

                    </div>
                </div>
                <div className='progam-content'>
                    <div className="">
                        <div className="p-2">
                            <Image className='subprogramImg' src={LeaderShipImg} alt="whyus img" />
                        </div>
                        <div className="subtitle-wrap">
                            <h1 className='whyus-title'>Leadership Capacities</h1>
                        </div>
                        <div className="subwrap">Equip women and youth with transformative leadership skills both formal and informal to
                            enable them to maximize their potential and improve their performance in day-to-day life
                        </div>
                        <Link to='/our-programs/leadership'><Button className='donate-btn' variant="info">Read More</Button></Link>

                    </div>
                </div>
                <div className='progam-content'>
                    <div>
                        <div className="">
                            <div className="p-2">
                                <Image className='subprogramImg' src={climateImg} alt="whyus img" />
                            </div>
                        </div>
                        <div className="subtitle-wrap">
                            <h1 className='whyus-title'>Climate Coaching</h1>
                        </div>
                        <div className="subwrap">
                            To empower women and youth to unleash their full potential and capacities to contribute to and drive climate change adaptation and sustainable solutions rooted in the principles of inclusion and equity.
                        </div>
                        <Link to='/our-programs/climate'><Button className='donate-btn' variant="info">Read More</Button></Link>

                    </div>
                </div>
            </div>
            <hr />
            <Donate />
        </div>
    )
}
export default Enterprise;