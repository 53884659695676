import React, { useState } from 'react'

import { Card } from 'react-bootstrap';


function Step2({ next, previous, data }) {
  const [address, setAddress] = useState(data.address);

  console.log('form data review: ', data);
  const handleNext = () => {
    next();
  };

  return (
    <div>
      {/* <h2>Step 2: Shipping Information</h2> */}
      <Card>
        <div className='stepCard'>
          <div>
            <p><span style={{ fontWeight: 'bold' }}>Name:</span> {' '} {data.name}</p>
            <p><span style={{ fontWeight: 'bold' }}>Phone Number:</span> {' '} {data.phone}</p>
            <p><span style={{ fontWeight: 'bold' }}>Email:</span> {' '} {data.email}</p>
            <p><span style={{ fontWeight: 'bold' }}>Amount:</span> ${' '} {data.amount}</p>
            <p><span style={{ fontWeight: 'bold' }}>Payment Method:</span> {' '} {data.ischecked}</p>
          </div>
          <button className='btn-prev' onClick={previous}>Previous</button>
          <button className='btn-next' onClick={handleNext}>Continue</button>
        </div>
      </Card>
    </div>
  );
}

export default Step2