import React,{ useState } from 'react'

import { Card } from 'react-bootstrap';

function Step3({ next, previous, data }) {
  const [paymentMethod, setPaymentMethod] = useState(data.paymentMethod);

  const handleNext = () => {
    next({ paymentMethod });
  };

  return (
    <div>
      <Card>
      <div className='stepCard'>
      <div>
        <p><span style={{fontWeight:'bold'}}>Bank Name: </span> {' '} Bank of Africa</p>
        <p><span style={{fontWeight:'bold'}}>Account Name:</span> {' '} Anyim wa community initiative Limited</p>
        <p><span style={{fontWeight:'bold'}}>Account Number:</span> {' '} 07657960011</p>
        <p><span style={{fontWeight:'bold'}}>Swift Code:</span> {' '} AFRIUGKA</p>
      </div>
      <button className='btn-prev' onClick={previous}>Previous</button>
      <button className='btn-next' onClick={handleNext}>Complete</button>
    </div>
    </Card>
    </div>
  );
}

export default Step3