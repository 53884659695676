import React from "react";
import { Link } from "react-router-dom";
import { Image, Button, Form } from "react-bootstrap";

import '../App.css'

import LivelihoodImg from '../assets/programs/livelihood_5.jpg'
import climateImg from '../assets/programs/climat.jpg'
import LeaderShipImg from '../assets/awci-train-leadership.jpeg'
import EnterpriseImg from '../assets/Enterprise.jpeg'
import Seedling from '../assets/programs/seedling.png'
import Donate from "./shared/Donate";

function Programmes() {


    return (
        <>
            <div className="seedling-container mt-5">
                <div>
                    <div className="text-center">
                        <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
                    </div>

                    <h1 className="text-center pt-2 progam-title">What We Do</h1>

                </div>
            </div>


            <div className="container">
                {/* <h1 className="header">Our Programs</h1> */}

                <div className="subprogramCard">

                    <div className='progam-content'>
                        <div>
                            <div className="p-2">
                                <Image className='subprogramImg ' src={LivelihoodImg} alt="whyus img" />
                            </div>
                            <div className="subtitle-wrap">
                                <h1 className='whyus-title'>Livelihood</h1>
                            </div>
                            <div className="subwrap">
                                To reduce poverty and inequality by generating employment among households by moving highly vulnerable households into sustainable livelihoods and towards economic stability.
                            </div>
                            <Link to='/our-programs/livelihood'><Button className='donate-btn' variant="info">Read More</Button></Link>

                        </div>
                    </div>
                    <div className='progam-content'>
                        <div>
                            <div className="p-2">
                                <Image className='subprogramImg ' src={EnterpriseImg} alt="whyus img" />
                            </div>
                            <div className="subtitle-wrap">
                                <h1 className='whyus-title'>Entrepreneurship</h1>
                            </div>
                            <div className="subwrap">To enhance the capacity and willingness of women and youth entrepreneurial skills and knowledge to innovatively create, organize and manage their business ventures sustainably.</div>
                            <Link to='/our-programs/enterprise'><Button className='donate-btn' variant="info">Read More</Button></Link>

                        </div>
                    </div>
                    <div className='progam-content'>
                        <div className="">
                            <div className="p-2">
                                <Image className='subprogramImg' src={LeaderShipImg} alt="whyus img" />
                            </div>
                            <div className="subtitle-wrap">
                                <h1 className='whyus-title'>Leadership Capacities</h1>
                            </div>
                            <div className="subwrap">Equip women and youth with transformative leadership skills both formal and informal to
                                enable them to maximize their potential and improve their performance in day-to-day life
                            </div>
                            <Link to='/our-programs/leadership'><Button className='donate-btn' variant="info">Read More</Button></Link>

                        </div>
                    </div>
                </div>
                <div className="subprogramCard pt-3">
                    <div className='progam-content'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="p-2">
                                    <Image className='subprogramImg' src={climateImg} alt="whyus img" />
                                </div>
                            </div>
                            <div className="col-md-6 align-progam-element">
                                <div className="">
                                    <div className="subtitle-wrap">
                                        <h1 className='whyus-title'>Climate Coaching</h1>
                                    </div>
                                    <div className="subwrap">
                                        To empower women and youth to unleash their full potential and capacities to contribute to and drive climate change adaptation and sustainable solutions rooted in the principles of inclusion and equity.
                                    </div>
                                    <Link to='/our-programs/climate'><Button className='donate-btn' variant="info">Read More</Button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="seedling-container mt-5">
                    <div>
                        <div className="text-center">
                            <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
                        </div>

                        <h1 className="text-center pt-2 progam-title">Our Impact</h1>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="svg-container">
                            <svg class="circle-svg" viewBox="0 0 48 48" >
                                <circle stroke="green" stroke-width="1px"
                                    fill="green" cx="50%" cy="45%" r="30%" />
                                {/* <line x1="0" y1="50%" x2="100%" y2="50%" stroke="blue" stroke-width="1" /> */}
                                <text text-anchor="middle" alignment-baseline="middle" x="50%" y="50%" font-size="10" fill="white">60</text>
                            </svg>
                        </div>
                        <div className="work-text-container">
                            <div className="work-text">
                                The number of young Mothers trained with tailoring skills
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div className="svg-container">
                        <svg class="circle-svg" viewBox="0 0 48 48" >
                            <circle stroke="green" stroke-width="1px"
                                fill="green" cx="50%" cy="45%" r="30%" />
                            {/* <line x1="0" y1="50%" x2="100%" y2="50%" stroke="blue" stroke-width="1" /> */}
                            <text text-anchor="middle" alignment-baseline="middle" x="50%" y="50%" font-size="10" fill="white">40</text>
                        </svg>
                        </div>
                        <div className="work-text-container">
                            <div className="work-text">
                                The number of young mothers trained with hairdressing skills
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div className="svg-container">
                        <svg class="circle-svg" viewBox="0 0 48 48" >
                            <circle stroke="green" stroke-width="1px"
                                fill="green" cx="50%" cy="45%" r="30%" />
                            <text text-anchor="middle" alignment-baseline="middle" x="50%" y="50%" font-size="10" fill="white">50</text>
                        </svg>
                        </div>
                        <div className="work-text-container">
                        <div className="work-text">
                        The number of young mothers with individual active businesses
                        </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div className="svg-container">
                        <svg class="circle-svg" viewBox="0 0 48 48" >
                            <circle stroke="green" stroke-width="1px"
                                fill="green" cx="50%" cy="45%" r="30%" />
                            {/* <line x1="0" y1="50%" x2="100%" y2="50%" stroke="blue" stroke-width="1" /> */}
                            <text text-anchor="middle" alignment-baseline="middle" x="50%" y="50%" font-size="10" fill="white">800</text>
                        </svg>
                        </div>
                        <div className="work-text-container">
                        <div className="work-text">
                        The number of women and youth coached on climate and biodiversity
                        </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="seedling-container mt-5">
                    <div>
                        <div className="text-center">
                            <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
                        </div>

                        <h1 className="text-center pt-2 progam-title">Where We Work</h1>

                    </div>
                </div>
                <div className="work-text-container">
                    <div className="work-text">
                        Anyim Wa Community Initiative currently operates in the districts of Kitgum, Omoro, Gulu, Nwoya,
                        and Amuru in the Acholi Sub-region of Northern Uganda,
                        reaching multiple remote and peri-urban communities.
                        Over the past years, the organization has delivered climate coaching, mentorship, skills training,
                        and supported struggling families in rural areas. Initially starting with volunteer coaching and mentorship programs for out-of-school youth and teenage mothers during the COVID era,
                        the organization expanded its programs after registration to include Livelihood Enterprise Development,
                        Strengthening Leadership, and other hands-on skills.
                    </div>
                </div>
                <hr />

                <div className="seedling-container mt-5">
                    <div>
                        <div className="text-center">
                            <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
                        </div>

                        <h1 className="text-center pt-2 progam-title">How We Work</h1>

                    </div>
                </div>
                <div className="work-text-container">
                    <div className="work-text">
                        We develop mechanisms and create safe spaces for learning and experience sharing by delivering tailored mentorship and coaching support that aligns with our clients' individual passions and desired skills.
                        We embrace the art of regenerative coaching and mentorship to empower communities with sustainable solutions for a better world they can enjoy.
                    </div>
                </div>
                <hr />

                <div className="pt-4">
                <Donate />
                </div>
            </div>

        </>
    );
}

export default Programmes;