import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import whyUsImage from '../assets/image1.jpeg'
import BeneficiaryImg from '../assets/programs/awci-nuts_0.jpg'
import '../App.css'

import VideoPlayer from './video/Video';

function Home() {
    return (
        <div>
            <div className='cover'>
                <div className='cover-content'>
                    <div>
                        <VideoPlayer />
                    </div>
                    {/* <div className='content-cover'>
                        <div class="button-container">
                            <Button className='donate-btn' variant="warning">Donate</Button>
                            <Link to='/about-us'><Button className='donate-btn' variant="info">Know More</Button></Link>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* Why Us  */}

            <div>
                <div className='whyus-container'>

                    <div className='whyus-content'>
                        <div>
                            <h1 className='whyus-title'>Why Us ? Why Now ?</h1>
                            <p className='whyus-text'>For over 20 years, Northern Uganda has been at war led by the LRA (Lord Resistance Army). Many of its citizens have lived in Internally Displaced Person (IDP) camps for most of that time. For younger Ugandans, it is the only home they have known. The relative peace over the past few years has encouraged the Ugandan government to close IDP camps and move people back to their family or clan land. Many of the returnees, majority of whom are women and girls, are predominantly agriculturalists on a subsistence basis. However, rural women and girls suffer widespread gender-based discrimination in laws, customs, and practices which causes severe inequalities in their ability to access, control, own, and use economic resources. This limits their participation in decision-making at all levels of economic management hence affecting the realization of Sustainable Development Goal (SDG) 5 of Gender Equality and Goal 8 of Economic Empowerment. Women’s economic rights and gender justice in Income Generation Activities (IGA) are fundamental pillars in the promotion and protection of women’s and youth welfare, As many still struggle during this recovery period, COVID-19 and its impacts disrupted the process, and creating another wound within a bigger wound, AWCI organization is determined to provide them with a ray of hope. “Transforming Lives for a Better Future” is an inspiring story of resilience, courage, and the transformative power of empowerment. Through this compelling narrative, we aim to raise funds to support our mission of unveiling hidden and untapped inner potentials of women and youth through life skills empowerment, mentorship, and coaching so that they can get access to resources and opportunities, to live and develop with dignity for a better future and changed society.</p>
                            <Link to="/our-programs"><Button className='info whatwedo-btn'>What We Do</Button></Link>
                        </div>
                    </div>
                    <Image className='whyusImg ' src={whyUsImage} alt="whyus img" />
                </div>
            </div>
            <div>
                <div className='whyus-container'>

                    <div className='whyus-content'>
                        <div>
                            <h1 className='whyus-title'>Our Beneficiary Story</h1>
                            <p className='aboutus-text'>Meet Concy,  a teenage mother who among many young girls got pregnant during COVID 19 when schools were closed for over two years in Uganda. Just like others she is a young mother who was trying to make ends meet for her child who already start school. She dropped out of school because her parents could not continue paying for her after her first child and her school mate who got her pregnant disappeared from the community. </p>
                            <p className='aboutus-text'>
                                The Executive Director Denis met Concy during research work in 2020 and when he went back for follow up in 2022 since she was a participant of the study. He had a chance of talking to her again. This time the tears flowing from her eyes were more as she expressed the difficult condition she was passing through. Concy is not the only young mother going through this in her community - there are many more. Denis asked her what she thought can be done to help her. “Empowerment is enough for me”, she answered. She needed help to acquire skills to start her own business. Skills in tailoring or hair dressing or anything that she could use to generate income. Concy has a passion for climate, and she wanted to be a nature defender because of the endless human activities that destroys the environment in her community like deforestation, poor disposal of plastics, among others.
                            </p>

                            <Link to='/our-beneficiary-story'><Button className='donate-btn' variant="info">Read More</Button></Link>
                        </div>
                    </div>
                    <Image className='ourBeneficiaryImg ' src={BeneficiaryImg} alt="lives change img" />
                </div>
            </div>
        </div>
    );
}

export default Home;
