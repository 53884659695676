// src/components/Navbar.js

import React, { useState } from 'react';
import { Navbar, Nav, Button, Image, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import '../App.css'

import Logo from '../assets/AWCI-logo.jpg';

const Header = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [showDropdown2, setShowDropdown2] = useState(false);
    const [showDropdown3, setShowDropdown3] = useState(false);

    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };

    const handleMouseEnter1 = () => {
        setShowDropdown1(true);
    };

    const handleMouseLeave1 = () => {
        setShowDropdown1(false);
    };

    const handleMouseEnter2 = () => {
        setShowDropdown2(true);
    };

    const handleMouseLeave2 = () => {
        setShowDropdown2(false);
    };

    const handleMouseEnter3 = () => {
        setShowDropdown3(true);
    };

    const handleMouseLeave3 = () => {
        setShowDropdown3(false);
    };
    return (
        <><Navbar expand="lg" id='head-nav'>
            <Navbar.Brand>
                <Link to="/">
                    <Image className='img' src={Logo} alt="Brand logo" />
                    {' '}

                </Link>
            </Navbar.Brand>
            <div>
                <Link className="nav-child" to='/home'>
                    Home
                </Link>
                <Link className="nav-child" to='/contact-us'>
                    Contact Us
                </Link>
                 <Link className="nav-child" to='/about-us'>
                    About Us
                </Link> 
                {/* <div> 
                Contact Us
            </div> */}
            </div>
        </Navbar>


            <Navbar expand="lg" id='nav'>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
                    <Nav>

                        <NavDropdown
                            title={<Link to='/our-programs' className="custom-dropdown-title">What We Do</Link>}
                            id="basic-nav-dropdown"
                            show={showDropdown}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <NavDropdown.Item as={Link} to='/our-programs/livelihood'>Livelihood</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to='/our-programs/enterprise'>Enterprise Development</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to='/our-programs/leadership'>Leadership Capacities</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to='/our-programs/climate'>Climate Coaching</NavDropdown.Item>
                        </NavDropdown>
                        {/* stories */}
                        <NavDropdown title={<span className="custom-dropdown-title">News & Stories</span>}
                            id="basic-nav-dropdown"
                            show={showDropdown1}
                            onMouseEnter={handleMouseEnter1}
                            onMouseLeave={handleMouseLeave1}
                        >
                            <NavDropdown.Item as={Link} to='/story-our-beneficiary'>Our Beneficiary Story</NavDropdown.Item>

                        </NavDropdown>
                        {/* Give */}
                        <NavDropdown
                            title={<span className="custom-dropdown-title">Ways To Give</span>}
                            id="basic-nav-dropdown"
                            show={showDropdown2}
                            onMouseEnter={handleMouseEnter2}
                            onMouseLeave={handleMouseLeave2}
                        >
                            <NavDropdown.Item as={Link} to='/donate'>Donate</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to='/donate-sewing-machine'>Donate Sewing Machine</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to='/donate-salon-equipment'>Donate Saloon Equipement</NavDropdown.Item>
                            {/* <NavDropdown.Divider /> */}
                            {/* <NavDropdown.Item href="#action/3.1">Donate Training Materials</NavDropdown.Item> */}
                            {/* <NavDropdown.Divider /> */}
                            {/* <NavDropdown.Item as={Link} to='#'>Become A Coach</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to='#'>Become A Mentor </NavDropdown.Item> */}
                        </NavDropdown>

                        {/* Get involve */}

                        <NavDropdown
                            title={<span className="custom-dropdown-title">Get Involved</span>}
                            id="basic-nav-dropdown"
                            show={showDropdown3}
                            onMouseEnter={handleMouseEnter3}
                            onMouseLeave={handleMouseLeave3}
                        >
                            <NavDropdown.Item href="#action/3.1">Fundraising</NavDropdown.Item>

                        </NavDropdown>
                    </Nav>

                    <div style={{ marginLeft: '7rem' }}>
                        <Button className='donate-btn' variant="warning" as={Link} to='/donate'>Donate</Button>
                    </div>


                </Navbar.Collapse>
                {/* <div className="ml-auto d-flex align-items-center">
         </div> */}
            </Navbar></>
    );
}

export default Header;
