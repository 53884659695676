import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import './App.css'

import Header from './navigation/Header';
import Home from './component/Home';
import Programmes from './component/Programmes';
import Climate from './component/programs/Climate'
import Livelihood from './component/programs/Livelihood'
import Enterprise from './component/programs/Enterprise'
import Leadership from './component/programs/Leadership'
import Blog from './component/Blog';
import AboutUs from './component/AboutUs';

import Footer from './navigation/Footer';
import BenificiariesStory from './component/newsStories/BenificiariesStory';
import Donate from './component/DonateView';
import DonateSewingMachine from './component/waysTogive/DonateSewingMachine';
import DonateSaloonEquipment from './component/waysTogive/DonateSaloonEquipment';
import CompleteDonation from './component/CompleteDonation';
import ContactUs from './component/contact/ContactUs';


function App() {

  return (
    <Router>
      <div className="home">
        <div className='header'>
          <Header />
        </div>

        <Routes>
          <Route path="/" exact element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/complete/donate/checkout" element={<CompleteDonation />} />
          <Route path="/donate-sewing-machine" element={<DonateSewingMachine />} />
          <Route path="/donate-salon-equipment" element={<DonateSaloonEquipment />} />
          <Route path="/our-programs" element={<Programmes />} />
          <Route path="/our-programs/livelihood" element={<Livelihood />} />
          <Route path="/our-programs/climate" element={<Climate />} />
          <Route path="/our-programs/enterprise" element={<Enterprise />} />
          <Route path="/our-programs/leadership" element={<Leadership />} />
          <Route path="/our-beneficiary-story" element={<Blog />} />
          <Route path="/story-our-beneficiary" element={<BenificiariesStory />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
        <div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
