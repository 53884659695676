import React from 'react'
import { Container, Image, Form, Button, Card } from 'react-bootstrap'

import Seedling from '../../assets/programs/seedling.png'
function ContactUs() {
    return (
        <Container>
            <div className="text-center pt-3">
                <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
            </div>
            <h1 className="text-center whyus-title">Contact Us</h1>
            <hr />
            <div>
                <div>
                    <div className="row mt-4 work-text-container">
                        <div className="col-md-8">
                            <div className='mt-3'>
                                <Form.Label htmlFor="donatAmount" id='label'><span style={{color:'red'}}>* {' '}</span>Your Full Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="donatAmount"
                                    placeholder="Enter your name"
                                    className='donat-input'
                                // onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                            <div className='mt-4'>
                                <Form.Label htmlFor="donatAmount" id='label'><span style={{color:'red'}}>* {' '}</span>Your Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="donatAmount"
                                    placeholder="Enter your email"
                                    className='donat-input'
                                // onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                            <div className='mt-4'>
                                <Form.Label htmlFor="donatAmount" id='label'>Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="donatAmount"
                                    placeholder="Enter your phone number"
                                    className='donat-input'
                                // onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                            <div className='mt-4'>
                                <Form.Label htmlFor="donatAmount" id='label'><span style={{color:'red'}}>* {' '}</span>Subject</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="donatAmount"
                                    placeholder="Enter subject"
                                    className='donat-input'
                                // onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                            <div className='mt-4'>
                                <Form.Label htmlFor="donatAmount" id='label'><span style={{color:'red'}}>* {' '}</span>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    // id="donatAmount"
                                    placeholder="Enter message"
                                    className='donat-input'
                                    rows={10}
                                // onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>

                            <div className='mt-4'>
                                <Button variant="success" className='btn-donate mt-2' >Send Message </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">

                    {/* {errors.amount && <p style={{ color: 'red' }}>{errors.amount}</p>} */}
                </div>
            </div>
        </Container>
    )
}

export default ContactUs