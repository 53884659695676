import React from "react";
import { Link } from "react-router-dom";
import { Image, Button } from "react-bootstrap";

// import LeaderShipImg from '../../assets/programs/livelihood.jpg'

import LivelihoodImg from '../../assets/programs/livelihood_5.jpg'
import climateImg from '../../assets/programs/climat.jpg'
import LeaderShipImg from '../../assets/awci-train-leadership.jpeg'
import EnterpriseImg from '../../assets/Enterprise.jpeg'
import Seedling from '../../assets/programs/seedling.png'

import Donate from "../shared/Donate";

function Leadership() {
    return (
        <div className="container">
            <div>
                <div>
                    <div className="text-center pt-3">
                        <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
                    </div>
                    <h1 className="text-center whyus-title">Leadership Capacities</h1>
                    <hr />
                    <Image src={LeaderShipImg} alt="" className="img-progam" />
                    <hr />
                    <div className="program-container">
                        <div>
                            <div className="wrap-program">
                                Equip women and youth with transformative leadership skills both formal and informal to
                                enable them to maximize their potential and improve their performance in day-to-day life
                            </div>

                            <div className="wrap-program">
                                Increase economic and social positive changes in the community influenced by
                                women and youth trained and knowledgeable in transformative leadership.
                            </div>

                            <div className="wrap-program">
                                Improve transferable and professional skills among women and youth such as
                                communication, public speaking, conflict management, character, team building and
                                teamwork.
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="subprogramCard">

                <div className='progam-content'>
                    <div>
                        <div className="p-2">
                            <Image className='subprogramImg ' src={EnterpriseImg} alt="whyus img" />
                        </div>
                        <div className="subtitle-wrap">
                            <h1 className='whyus-title'>Entrepreneurship</h1>
                        </div>
                        <div className="subwrap">To enhance the capacity and willingness of women and youth entrepreneurial skills and knowledge to innovatively create, organize and manage their business ventures sustainably.</div>
                        <Link to='/our-programs/enterprise'><Button className='donate-btn' variant="info">Read More</Button></Link>

                    </div>
                </div>
                <div className='progam-content'>
                    <div className="">
                        <div className="p-2">
                            <Image className='subprogramImg' src={LivelihoodImg} alt="whyus img" />
                        </div>
                        <div className="subtitle-wrap">
                            <h1 className='whyus-title'>Livelihood</h1>
                        </div>
                        <div className="subwrap">

                            To reduce poverty and inequality by generating employment among households by moving highly vulnerable households into sustainable livelihoods and towards economic stability.
                        </div>
                        <Link to='/our-programs/livelihood'><Button className='donate-btn' variant="info">Read More</Button></Link>

                    </div>
                </div>
                <div className='progam-content'>
                    <div>
                        <div className="">
                            <div className="p-2">
                                <Image className='subprogramImg' src={climateImg} alt="whyus img" />
                            </div>
                        </div>
                        <div className="subtitle-wrap">
                            <h1 className='whyus-title'>Climate Coaching</h1>
                        </div>
                        <div className="subwrap">
                            To empower women and youth to unleash their full potential and capacities to contribute to and drive climate change adaptation and sustainable solutions rooted in the principles of inclusion and equity.
                        </div>
                        <Link to='/our-programs/climate'><Button className='donate-btn' variant="info">Read More</Button></Link>

                    </div>
                </div>
            </div>
            <hr />
            <Donate />
        </div>
    )
}
export default Leadership;