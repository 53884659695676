import React from "react";
import { Image } from "react-bootstrap";

import BeneficiaryImg from '../assets/programs/awci-nuts_0.jpg'

function Blog() {
    return (
        <div>
            <div className="aboutus-cover">
            <div className="aboutus-header">
                <h1 className="aboutus-title text-center">Our Beneficiary Story</h1>

                <h3 className="text-center aboutus-subtitle">Transforming Live For a Better Future</h3>

            </div>
        </div>
            <div class="sections" >
                 <div class="whyus-container">
                    <Image className='programImg' src={BeneficiaryImg} alt="lives change img" />
               
                <div className='whyus-content'>
                    <div>
                        <h3 className='whyus-title'>Meet Concy</h3>
                        <p className='aboutus-text'>
                            Concy, a teenage mother who among many young girls got pregnant during COVID-19 when schools were closed for over two years in Uganda. Just like others, she is a young mother who was trying to make ends meet for her child who already started school. She dropped out of school because her parents could not continue paying for her after her first child, and her schoolmate who got her pregnant disappeared from the community.
                        </p>
                        <p className='aboutus-text'>
                            The Executive Director Denis met Concy during research work in 2020 and when he went back for follow-up in 2022 since she was a participant of the study. He had a chance of talking to her again. This time the tears flowing from her eyes were more as she expressed the difficult condition she was passing through. Concy is not the only young mother going through this in her community - there are many more. Denis asked her what she thought could be done to help her. “Empowerment is enough for me,” she answered. She needed help to acquire skills to start her own business, such as tailoring or hairdressing or anything that she could use to generate income. Concy has a passion for climate and wanted to be a nature defender because of the endless human activities that destroy the environment in her community like deforestation, poor disposal of plastics, among others.
                        </p>
                        <p className='aboutus-text'>
                            Denis could not find any better way to support her and since there are many of such cases in Gulu and the neighboring districts, Denis decided to start up A wang Oo coaching and mentorship program for young mothers and out of school youth to empower them with hands-on skills mentorship and also integrated climate coaching as a way to involve young people in defending nature for future generations.
                        </p>
                        <p className='aboutus-text'>
                            Wang Oo is an Acholi word referring to an evening fireplace. It’s a time when young people traditionally learnt real-life experiences through storytelling, riddles, and songs from the elders. It would bring together fathers, mothers, grandparents, children, grandchildren all in one place.
                        </p>
                        <p className='aboutus-text'>
                            The eight-month Wang Oo coaching and mentorship program was implemented in an economic empowerment and nature defender's hub for young mothers and out of school youth. Hands-on skills such as tailoring, hairdressing, product making like bar and liquid soap, bread making, art and craft, paper bags, books, shoe making, fireless cookers among other skills. The program also had soft skills like creativity, innovation, decision making, critical thinking involved, and hard skills topics like saving, marketing, planning, branding, business plan, resource mobilization among others. Climate coaching was also delivered as an additional package to get women and youth involved in protecting and defending climate in communities. They are challenged and started advocating for nature protection from duty bearers at local, national, and international levels.
                        </p>
                        <p className='aboutus-text'>
                            This program was successful after funds raised through a GoGetFunding campaign and other support from individuals within the Climate Coaching Alliance. Over 40 teenage mothers and out of school youth were trained with skills in hairdressing and tailoring as a main course in addition to soft and hard skills, product making skills, and climate coaching sessions. These sessions saw the formation of climate defenders who continue to defend the rights of nature at grassroots levels. Community outreaches were also conducted to end gender-based violence and discrimination against girls’ education in 8 villages with over 100 participants attending per village. To further increase the impact, a mega girls’ boot camp was held with over 85 participants attending.
                        </p>
                        <p className='aboutus-text'>
                            As Concy’s and other young mothers' stories unfold, we delve into the transformative impact of coaching, mentorship, and empowerment for women and youth living in difficult conditions. We witness their resilience, their thirst for knowledge, and their determination to overcome the challenges they face. We also showcase the tireless efforts of our program, which goes beyond providing basic literacy and numeracy skills. Through innovative programs, we foster critical thinking, creativity, and emotional well-being, enabling these young minds to envision a brighter future.
                        </p>
                        <p className='aboutus-text'>
                            As a result, Denis teamed up with Harriet and they founded Anyim Wa Community Initiative Limited in October 2023 to formally change Wang Oo program into a registered organization with support from Dr. Josie McLean, Grattan Donnelly, Tom Finlay, and Stuart Pickles. The vision is to see a community where women and youth can translate their inner potentials and capabilities to transform their lives and communities into a better place of living.
                        </p>
                        <p className='aboutus-text'>
                            With your support, we can expand our reach, reaching more women and youth and offering mentorship and coaching programs to thousands of women and youth like Concy. Your contribution will help strengthen organization programs, provide essential learning resources, and train dedicated mentors and coaches who serve as beacons of hope in these women and youth’s lives.
                        </p>
                        <p className='aboutus-text'>
                            Join us on this inspiring journey as we turn the page for women and youth and unlock their full potential. Together, we can provide them with the tools they need to rebuild their lives and become the leaders of tomorrow.
                        </p>
                        {/* <Link to='/our-beneficiary-story'><Button className='donate-btn' variant="info">Read More</Button></Link> */}
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default Blog;