import React from 'react';

const VideoPlayer = () => {
  return (
    <video width="800" autoPlay muted loop controls>
      <source src="/anyim_wa.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;
