import React from 'react';

import { Link } from 'react-router-dom';
import { Image, Button } from 'react-bootstrap';

import Logo from '../assets/AWCI-logo.jpg';
const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  return (
    <div className='footer-container'>
      <div className='footer-logo'><Link to="/">
        <Image className='footer-img' src={Logo} alt="Brand logo" />
        {' '}

      </Link>
        <div>
          <Button className='btn-footer'>Contact Us</Button>
        </div>
        <div>
          <p>Follow us on</p>
          <a href="#" class="fab fa-linkedin"></a>
          <a href="#" class="fab fa-facebook-f"></a>
          <a href="#" class="fab fa-twitter"></a>
          <a href="#" class="fab fa-instagram"> </a>
        </div>
      </div>
      <div className='footer'>
        <div>
          <ul>
            <li>Anyimwa Community Initiative Limited</li>
            <li>Pece-Laroo Division</li>
            <li>P.O.Box 360671</li>
            <li>Gulu, Uganda</li>
            <li>+256 (0) 779232824</li>
          </ul>
          <div className='copyright'><p >awci &copy; <span>{year}</span></p></div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
