import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';

import '../../Custom.css'

import BankImg from '../../assets/bank.jpeg'
function Step1({ next, data }) {
  const [name, setName] = useState(data.name || '');
  const [phone, setPhone] = useState(data.name || '');
  const [email, setEmail] = useState(data.email || '');
  const [ischecked, setIsChecked] = useState(data.paymentMethod)
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [amount, setAmount] = useState(data.amount || '')
  const [other, setOther] = useState('' || 0)
  const [errors, setErrors] = useState({});

  const donatAmount = localStorage.getItem('amount')
  useEffect(() => {
    if (donatAmount) {
      setAmount(donatAmount);
    }
    //  if(selectedCheckbox){
    //   setAmount(selectedCheckbox);
    //  }
    switch (selectedCheckbox) {
      case 100:
        setAmount(100);
        break;
      case 50:
        setAmount(50);
        break;
      case 25:
        setAmount(25);
        break;
      case 'other':
        setAmount(other);
        break;

      default:
        break;
    }

  });
  // Handle validation
  const validate = () => {
    let validationErrors = {};
    if (!amount) {
      validationErrors.amount = 'Amount is required';
    } else if (isNaN(amount) || amount <= 0) {
      validationErrors.amount = 'Please enter a valid amount';
    }
    if (!name) {
      validationErrors.name = 'Full name is required';
    }
    if (!phone) {
      validationErrors.phone = 'Phone Number is required';
    }
    if (!email) {
      validationErrors.email = 'Email is required';
    }
    if (!ischecked) {
      validationErrors.paymentMethod = 'Payment Method is required';
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleCheckboxChange = (index) => {
    setSelectedCheckbox(index);
    localStorage.clear();
  };

  // Payment methods
  const handleCheckboxPaymentMethod = (index) => {
    setIsChecked(index);
  };



  console.log('passed donation amount: ', amount);

  const handleNext = (e) => {
    e.preventDefault();
    if (validate()) {
      next({ name, email, phone, amount, ischecked });
    }
  };

  return (
    <div>
      <h2 className='pt-2 progam-title'>Donation</h2>
      <Row>
        <Col md={1}>
          <div class="circle-with-tick"></div>
        </Col>
        <Col >
          <p>I would like to make a donation</p>
        </Col>
      </Row>
      <div className='m-4'>
        <Row>
          <Col md={2}>
            <label className="container">$100
              <input
                type="checkbox"
                checked={selectedCheckbox === 100}
                onChange={() => handleCheckboxChange(selectedCheckbox === 100 ? null : 100)}
              />
              <span className="checkmark"></span>
            </label>
          </Col>
          <Col md={2}>
            <label className="container">$50
              <input
                type="checkbox"
                checked={selectedCheckbox === 50}
                onChange={() => handleCheckboxChange(selectedCheckbox === 50 ? null : 50)}
              />
              <span className="checkmark"></span>
            </label>
          </Col>
          <Col md={2}>
            <label className="container">$25
              <input
                type="checkbox"
                checked={selectedCheckbox === 25}
                onChange={() => handleCheckboxChange(selectedCheckbox === 25 ? null : 25)}
              />
              <span className="checkmark"></span>
            </label>
          </Col>
          <Col md={5}>
            <label className="container">Other
              <input
                type="checkbox"
                checked={selectedCheckbox === 'other'}
                onChange={() => handleCheckboxChange(selectedCheckbox === 'other' ? null : 'other')}
              />
              <span className="checkmark"></span>
              {selectedCheckbox === 'other' && (
                <div>
                  <input type="text" value={other} onChange={(e) => setOther(e.target.value)} placeholder='Please Enter Amount' />
                </div>
              )}
            </label>
          </Col>
          {errors.amount && <p style={{ color: 'red' }}>{errors.amount}</p>}
        </Row>
      </div>

      <Row>
        <Col md={1}>
          <div className="circle-with-tick"></div>
        </Col>
        <Col >
          <p className='text-warning'>you are donating $100</p>
        </Col>
      </Row>

      <div>
        <h2 className='pt-2 progam-title'>Contact information</h2>

        <div>
          <label>
            Name <span>*</span>
          </label>
          <input type="text" placeholder='Enter name' value={name} onChange={(e) => setName(e.target.value)} />
          {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
        </div>
        <div>
          <label>
            Phone Number <span>*</span>
          </label>
          <input type="text" placeholder='Enter phone number' value={phone} onChange={(e) => setPhone(e.target.value)} />
          {errors.phone && <p style={{ color: 'red' }}>{errors.phone}</p>}
        </div>
        <div>
          <label>
            Email <span>*</span>
          </label>
          <input type="email" placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)} />
          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
        </div>
        <p>We need your Phone Number and Email address to send you a receipt of your payment.</p>
      </div>
      <div>
        <h2 className='pt-2 progam-title'>Payment information</h2>

        <div className='mt-4'>
          <h3>Payment Methods <span>*</span></h3>
          <div className='mt-4'>
            <label variant="outline-dark" className="container">
              <Image className='seedImg text-center' src={BankImg} alt="bank img" />{' '}   Bank Transfer
              <input type="checkbox"
                checked={ischecked === 'Bank Transfer'}
                onChange={() => handleCheckboxPaymentMethod(ischecked === 'Bank Transfer' ? null : 'Bank Transfer')}
              />
              <span className="checkmark"></span>
            </label>
            {errors.paymentMethod && <p style={{ color: 'red' }}>{errors.paymentMethod}</p>}
          </div>
        </div>
      </div>
      <button className='btn-checkout' onClick={handleNext}>Confirm Payment Method and Review</button>
    </div>
  );
}

export default Step1