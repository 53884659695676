import React, { useState } from 'react';
import { Image, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Step1 from '../component/stepByStep/Step1';
import Step2 from '../component/stepByStep/Step2';
import Step3 from '../component/stepByStep/Step3';
import Summary from '../component/stepByStep/Summary';

import Seedling from '../assets/programs/seedling.png'

function CompleteDonation() {
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        address: '',
        paymentMethod: '',
    });

    const handleNext = (data) => {
        setFormData({ ...formData, ...data });
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleComplete = (data) => {
        setFormData({ ...formData, ...data });
        // alert('Order completed!');
        // console.log('Order data:', formData);
        navigate('/home');
    };

    const steps = [
        <Step1 next={handleNext} data={formData} />,
        <Step2 next={handleNext} previous={handlePrevious} data={formData} />,
        <Step3 next={handleComplete} previous={handlePrevious} data={formData} />,
        <Summary data={formData} />,
    ];

    return (
        <Container>
            <div className="seedling-container mt-5">
                <div>
                    <div className="text-center">
                        <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
                    </div>

                    <h1 className="text-center pt-2 progam-title">Support Us By Donating</h1>

                </div>
            </div>

            <div className="checkout-progress d-flex justify-content-center mt-5">

                {currentStep === 1 ? <>
                    <div className="triangle2-active"></div>
                    <div className="step active-step">Order information</div>
                    <div className="triangle-active"></div>
                </> : <>
                    <div className="triangle2-incomplete"></div>
                    <div className="step incomplete">Order information</div>
                    <div className="triangle-incomplete"></div>
                </>}
                {currentStep === 2 ? <>
                    <div className="triangle2-active"></div>
                    <div className="step active-step">Review</div>
                    <div className="triangle-active"></div>
                </> : <>
                    <div className="triangle2-incomplete"></div>
                    <div className="step incomplete">Review</div>
                    <div className="triangle-incomplete"></div>
                </>}

                {currentStep === 3 ? <>
                    <div className="triangle2-active"></div>
                    <div className="step active-step">Complete Payment</div>
                    <div className="triangle-active"></div>
                </> : <>
                    <div className="triangle2-incomplete"></div>
                    <div className="step incomplete">Complete Payment</div>
                    <div className="triangle-incomplete"></div>
                </>}
            </div>
            <div className='d-flex justify-content-center mt-5'>

                {steps[currentStep - 1]}
            </div>
        </Container>
    );
}

export default CompleteDonation