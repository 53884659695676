import React from 'react'
import { Image, Container,Card, Row, Col } from 'react-bootstrap'


import Seedling from '../assets/programs/seedling.png'
import Donate from './shared/Donate'

function DonateView() {
    return (
        <Container>
            <div className="seedling-container mt-5">
                <div>
                    <div className="text-center">
                        <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
                    </div>

                    <h1 className="text-center pt-2 progam-title">Support Anyim Wa Community Initiative</h1>

                </div>
            </div>
            <div className='pt-5'>
                {/* <Donate /> */}
                <p style={{color:'red'}}><span>* {' '}</span>Please our dear supporters,we would like to let you know that our online payment is yet in place.</p>
                <p style={{color:'red'}}><span>* {' '}</span>We are working hard to put it in place.</p>
                <p style={{color:'red'}}><span>*{' '}</span>We currently accept bank transfers and swifts. feel free to support via bank transfers using the details below.</p>
                <Card>
                    <div className='stepCard'>
                        <div>
                            <p><span style={{ fontWeight: 'bold' }}>Bank Name: </span> {' '} Bank of Africa</p>
                            <p><span style={{ fontWeight: 'bold' }}>Account Name:</span> {' '} Anyim wa community initiative Limited</p>
                            <p><span style={{ fontWeight: 'bold' }}>Account Number:</span> {' '} 07657960011</p>
                            <p><span style={{ fontWeight: 'bold' }}>Swift Code:</span> {' '} AFRIUGKA</p>
                        </div>
                    </div>
                </Card>
            </div>
        </Container>
    )
}

export default DonateView