import React from 'react'

function Summary({ data }) {
  return (
    <div>
    <h2>Summary</h2>
    <p><strong>Name:</strong> {data.name}</p>
    <p><strong>Email:</strong> {data.email}</p>
    <p><strong>Address:</strong> {data.address}</p>
    <p><strong>Payment Method:</strong> {data.paymentMethod}</p>
  </div>
  )
}

export default Summary