import React from "react";
import { Link } from "react-router-dom";
import { Image, Button } from "react-bootstrap";

import LivelihoodImg from '../../assets/programs/livelihood.jpg'
import EnterpriseImg from '../../assets/Enterprise.jpeg'
import climateImg from '../../assets/programs/climat.jpg'
import LeaderShipImg from '../../assets/awci-train-leadership.jpeg'
import Seedling from '../../assets/programs/seedling.png'

import Donate from "../shared/Donate";

function Livelihood() {
    return (
        <div className="container">
            <div>
                <div>
                    <div className="text-center pt-3">
                        <Image className='seedImg text-center' src={Seedling} alt="whyus img" />
                    </div>
                    <h1 className="text-center whyus-title">Livelihood</h1>
                    <hr />
                    <Image src={LivelihoodImg} alt='' className="img-progam" />
                    <hr />
                    <div className="program-container">
                        <div>
                            <div className="wrap-program">
                                To reduce poverty and inequality by generating employment among households by moving
                                highly vulnerable households into sustainable livelihoods and towards economic stability.
                            </div>

                            <div className="wrap-program">
                                Strengthen the economic capacity of women and youth to acquire their fundamental
                                necessities such as education, food, housing, clothes, and more.
                            </div>

                            <div className="wrap-program">
                                Increase the capacity of women and youth to detect, mitigate, and respond appropriately
                                to issues that undermine their survival.
                            </div>

                            <div className="wrap-program">
                                Improve supportive environments for women and youth in entrepreneurial activities
                                and abilities to access markets and technology.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <hr />
            <div className="subprogramCard">

                <div className='progam-content'>
                    <div>
                        <div className="p-2">
                            <Image className='subprogramImg ' src={EnterpriseImg} alt="whyus img" />
                        </div>
                        <div className="subtitle-wrap">
                            <h1 className='whyus-title'>Entrepreneurship</h1>
                        </div>
                        <div className="subwrap">To enhance the capacity and willingness of women and youth entrepreneurial skills and knowledge to innovatively create, organize and manage their business ventures sustainably.</div>
                        <Link to='/our-programs/enterprise'><Button className='donate-btn' variant="info">Read More</Button></Link>

                    </div>
                </div>
                <div className='progam-content'>
                    <div className="">
                        <div className="p-2">
                            <Image className='subprogramImg' src={LeaderShipImg} alt="whyus img" />
                        </div>
                        <div className="subtitle-wrap">
                            <h1 className='whyus-title'>Leadership Capacities</h1>
                        </div>
                        <div className="subwrap">Equip women and youth with transformative leadership skills both formal and informal to
                            enable them to maximize their potential and improve their performance in day-to-day life
                        </div>
                        <Link to='/our-programs/leadership'><Button className='donate-btn' variant="info">Read More</Button></Link>

                    </div>
                </div>
                <div className='progam-content'>
                    <div>
                        <div className="">
                            <div className="p-2">
                                <Image className='subprogramImg' src={climateImg} alt="whyus img" />
                            </div>
                        </div>
                        <div className="subtitle-wrap">
                            <h1 className='whyus-title'>Climate Coaching</h1>
                        </div>
                        <div className="subwrap">
                            To empower women and youth to unleash their full potential and capacities to contribute to and drive climate change adaptation and sustainable solutions rooted in the principles of inclusion and equity.
                        </div>
                        <Link to='/our-programs/climate'><Button className='donate-btn' variant="info">Read More</Button></Link>

                    </div>
                </div>
            </div>
            <hr />
            <Donate />
        </div>
    )
}
export default Livelihood;